import React, { useState, useEffect } from 'react';
import ReactExport from "react-data-export";
import { useTranslation } from 'react-i18next';
import { ArrowDownOnSquareIcon } from '@heroicons/react/24/outline'

import { downloadExport, getTopAssets, getTopVideos } from 'api/reports';
import { getMonthlyConflicts, getMonthlyClaims } from 'api/expdata';

const DownloadButton = () => {
  return (<button
    type="button"
    className="block inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
  >
    <ArrowDownOnSquareIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
    Download Report
  </button>)
}

const DownloadExport = ({ uid, name, isMech, startYear, startMonth, startMonthName }) => {
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
  const { t } = useTranslation();
  const [downloadStatus, setDownloadStatus] = useState('pending');
  const [generalSummary, setGeneralSummary] = useState();
  const [adRates, setAdRates] = useState();
  const [shorts, setShorts] = useState();
  const [conflictsData, setConflictsData] = useState();
  const [claimData, setClaimData] = useState();
  const [topAssets, setTopAssets] = useState();
  const [topVideos, setTopVideos] = useState();

  useEffect(() => {
    setDownloadStatus('processing');
    const fetchDetails = async () => {
      try {
        const exportData = await downloadExport(uid, startYear, startMonth, startYear, startMonth);
        const topAssetsData = await getTopAssets(uid, startYear, startMonth, startYear, startMonth);
        const topVideosData = await getTopVideos(uid, startYear, startMonth, startYear, startMonth);

        setGeneralSummary(exportData?.data?.performance_summary);
        setShorts(exportData?.data?.shorts);
        setAdRates(exportData?.data?.ad_rates);
        setTopAssets(topAssetsData?.data?.data);
        setTopVideos(topVideosData?.data?.data);

        if (name) {
          const conflicts = await getMonthlyConflicts(startMonth, startYear, null, null, name);
          const claims = await getMonthlyClaims(startMonth, startYear, null, null, name);
          setConflictsData(conflicts?.data);
          setClaimData(claims?.data);
        }

        setDownloadStatus('success');
      } catch (error) {
        console.log(error);
        setDownloadStatus('failed');
      }
    };
    fetchDetails();
  }, []);

  const findAdRates = (adType, key) => {
    const elem = adRates?.find((e) => e.ad_type === adType);
    return elem;
  }

  const getPerformanceData = () => {
    const data = [
      [
        {'value': t('YouTube Monthly Report'), style: {font: {sz: "16", bold: true}}},
      ],
      [
        {'value': t('Summary Page'), style: {font: {sz: "16", bold: true}}},
      ],
      [],
      [
        {'value': startMonthName + ' ' + startYear},
      ],
      [
        {'value': '', style: {fill: {patternType: "solid", fgColor: {rgb: "EA9999"}}}},
        {'value': t('General'), style: {fill: {patternType: "solid", fgColor: {rgb: "EA9999"}}}},
        {'value': '', style: {fill: {patternType: "solid", fgColor: {rgb: "EA9999"}}}},
        {'value': '', style: {fill: {patternType: "solid", fgColor: {rgb: "EA9999"}}}},
      ],
      [],
      [
        {'value': t('Estimated Earnings'), style: {font: {bold: true}}},
        {'value': generalSummary?.estimated_earnings ? "$" + (parseFloat(generalSummary?.estimated_earnings).toFixed(2)).toString() : 'N/A', style: {font: {bold: true}}},
      ],
      [
        {'value': t('Estimated AVOD Revenue')},
        {'value': generalSummary?.avod_revenue ? "$" + (parseFloat(generalSummary?.avod_revenue).toFixed(2)).toString() : 'N/A'},
      ],
      [
        {'value': t('SVOD Music Earnings')},
        {'value': generalSummary?.svod_revenue ? "$" + (parseFloat(generalSummary?.svod_revenue).toFixed(2)).toString() : 'N/A'},
      ],
      [
        {'value': t('Short Revenue Earnings')},
        {'value': shorts?.gross_revenue ? "$" + (parseFloat(shorts?.gross_revenue).toFixed(2)).toString() : 'N/A'},
      ],
      [
        {'value': t('Estimated Monetized Playbacks')},
        {'value': generalSummary?.monetized_playbacks || 'N/A'},
      ],
      [
        {'value': t('Total Views')},
        {'value': generalSummary?.views || 'N/A'},
      ],
      [
        {'value': t('Watch Time (hours)')},
        {'value': generalSummary?.estimated_minutes_watched !== null ? parseInt(generalSummary?.estimated_minutes_watched / 60) : '0'},
      ],
      [
        {'value': t('Playback based CPM')},
        {'value': generalSummary?.playback_based_cpm ? "$" + (parseFloat(generalSummary?.playback_based_cpm).toFixed(2)).toString() : 'N/A'},
      ],
      [],
      [
        {'value': '', style: {fill: {patternType: "solid", fgColor: {rgb: "EA9999"}}}},
        {'value': t('Performance/Optimization'), style: {fill: {patternType: "solid", fgColor: {rgb: "EA9999"}}}},
        {'value': '', style: {fill: {patternType: "solid", fgColor: {rgb: "EA9999"}}}},
        {'value': '', style: {fill: {patternType: "solid", fgColor: {rgb: "EA9999"}}}},
      ],
      [],
      [
        {'value': t('Manual Claims Generated (YouTube)')},
        {'value': claimData?.yt_claims || 0},
      ],
      [
        {'value': t('Manual Claims Generated (Mechanicals)')},
        {'value': claimData?.mech_claims || 0},
      ],
      [],
      [
        {'value': '', style: {fill: {patternType: "solid", fgColor: {rgb: "EA9999"}}}},
        {'value': t('AdRates'), style: {fill: {patternType: "solid", fgColor: {rgb: "EA9999"}}}},
        {'value': '', style: {fill: {patternType: "solid", fgColor: {rgb: "EA9999"}}}},
        {'value': '', style: {fill: {patternType: "solid", fgColor: {rgb: "EA9999"}}}},
      ],
      [
        {'value': ''},
        {'value': t('Playback-based CPM')},
        {'value': t('Playback-based gross revenue')},
        {'value': t('Ad Impressions')},
      ],
    ];

    const adRatesData = [
      {
        "label": t("Skippable Video Ads (Auction)"),
        "key": "auctionTrueviewInstream",
        "playback_based_cpm": null,
        "playback_based_gross_revenue": null,
        "ad_impressions": null
      },
      {
        "label": t("Non-skippable video ads (Reserved)"),
        "key": "reservedInstream",
        "playback_based_cpm": null,
        "playback_based_gross_revenue": null,
        "ad_impressions": null
      },
      {
        "label": t("Display Ads (Auction)"),
        "key": "auctionDisplay",
        "playback_based_cpm": null,
        "playback_based_gross_revenue": null,
        "ad_impressions": null
      },
      {
        "label": t("Non-skippable video ads (Auction)"),
        "key": "auctionInstream",
        "playback_based_cpm": null,
        "playback_based_gross_revenue": null,
        "ad_impressions": null
      },
      {
        "label": t("Skippable Video Ads (Reserved)"),
        "key": "reservedInstreamSelect",
        "playback_based_cpm": null,
        "playback_based_gross_revenue": null,
        "ad_impressions": null
      },
      {
        "label": t("Bumper Ads (Auction)"),
        "key": "auctionBumperInstream",
        "playback_based_cpm": null,
        "playback_based_gross_revenue": null,
        "ad_impressions": null
      },
      {
        "label": t("Bumper Ads (Reserved)"),
        "key": "reservedBumperInstream",
        "playback_based_cpm": null,
        "playback_based_gross_revenue": null,
        "ad_impressions": null
      },
      {
        "label": t("Unknown"),
        "key": "unknown",
        "playback_based_cpm": null,
        "playback_based_gross_revenue": null,
        "ad_impressions": null
      }
    ];

    adRatesData.map((rowData, idx) => {
      const elem = findAdRates(rowData.key);
      const playbackBasedCpm = elem?.cpm || '';
      const playbackBasedGrossRevenue = elem?.gross_revenue || '';
      const adImpressions = elem?.ad_impressions || '';
      data.push([
        {'value': rowData?.label},
        {'value': (playbackBasedCpm && '$' + parseFloat(playbackBasedCpm).toFixed(2)) || ''},
        {'value': (playbackBasedGrossRevenue && '$' + parseFloat(playbackBasedGrossRevenue).toFixed(2))},
        {'value': (adImpressions && adImpressions)},
      ]);
    });

    data.push([]);
    data.push([
      {'value': '', style: {fill: {patternType: "solid", fgColor: {rgb: "EA9999"}}}},
      {'value': t('Conflict Resolutions'), style: {fill: {patternType: "solid", fgColor: {rgb: "EA9999"}}}},
      {'value': '', style: {fill: {patternType: "solid", fgColor: {rgb: "EA9999"}}}},
      {'value': '', style: {fill: {patternType: "solid", fgColor: {rgb: "EA9999"}}}},
    ]);
    data.push([
      {'value': t('Total assets resolved in our favor')},
      {'value': conflictsData?.total_assets || 0},
    ]);
    data.push([
      {'value': t('Total daily views resolved')},
      {'value': conflictsData?.total_daily_views || 0},
    ]);
    data.push([]);
    data.push([
      {'value': t('Notable Resolutions')},
    ]);
    data.push([
      {'value': t('Title')},
      {'value': t('Daily Views')},
      {'value': t('SR Asset ID')},
    ]);

    conflictsData?.notable_resolutions?.map((rowData, idx) => {
      data.push([
        {'value': rowData.title || ''},
        {'value': rowData.approx_daily_views || ''},
        {'value': rowData.asset_id || ''},
      ]);
    });

    const multiDataSet = [
      {
        columns: [
            {title: "", width: {wpx: 200}},
            {title: "", width: {wpx: 120}},
            {title: "", width: {wpx: 170}},
            {title: "", width: {wpx: 120}},
        ],
        data: data
      }
    ];

    return multiDataSet;
  };

  const ytManualClaimsData = () => {
    const sortedData = claimData?.yt_results?.sort((a,b) => b.videos_claimed - a.videos_claimed);
    const data = [];

    sortedData?.map((rowData, idx) => {
      data.push([
        {'value': rowData.song_title || ''},
        {'value': rowData.artist || ''},
        {'value': rowData.asset_id || ''},
        {'value': rowData.videos_claimed || 0},
      ])
    });

    if (data.length === 0) {
      data.push([
        {'value': ''},
        {'value': ''},
        {'value': ''},
        {'value': ''},
      ])
    }

    data.push([
      {'value': t('Total'), style: {fill: {patternType: "solid", fgColor: {rgb: "EA9999"}}}},
      {'value': '', style: {fill: {patternType: "solid", fgColor: {rgb: "EA9999"}}}},
      {'value': '', style: {fill: {patternType: "solid", fgColor: {rgb: "EA9999"}}}},
      {'value': claimData?.yt_claims || 0, style: {fill: {patternType: "solid", fgColor: {rgb: "EA9999"}}}},
    ]);

    const multiDataSet = [
      {
        columns: [
            {title: t('Song Title'), width: {wpx: 200}, style: {fill: {patternType: "solid", fgColor: {rgb: "EA9999"}}}},
            {title: t('Artist'), width: {wpx: 120}, style: {fill: {patternType: "solid", fgColor: {rgb: "EA9999"}}}},
            {title: t('Asset ID'), width: {wpx: 170}, style: {fill: {patternType: "solid", fgColor: {rgb: "EA9999"}}}},
            {title: t('Videos Claimed'), width: {wpx: 120}, style: {fill: {patternType: "solid", fgColor: {rgb: "EA9999"}}}},
        ],
        data: data
      }
    ];

    return multiDataSet;
  };

  const mechManualClaimsData = () => {
    const sortedData = claimData?.mech_results?.sort((a,b) => b.videos_claimed - a.videos_claimed);
    const data = [];

    sortedData?.map((rowData, idx) => {
      data.push([
        {'value': rowData.song_title || ''},
        {'value': rowData.artist || ''},
        {'value': rowData.platform || ''},
        {'value': rowData.asset_id || ''},
        {'value': rowData.videos_claimed || 0},
      ])
    });

    if (data.length === 0) {
      data.push([
        {'value': ''},
        {'value': ''},
        {'value': ''},
        {'value': ''},
      ])
    }

    data.push([
      {'value': t('Total'), style: {fill: {patternType: "solid", fgColor: {rgb: "EA9999"}}}},
      {'value': '', style: {fill: {patternType: "solid", fgColor: {rgb: "EA9999"}}}},
      {'value': '', style: {fill: {patternType: "solid", fgColor: {rgb: "EA9999"}}}},
      {'value': '', style: {fill: {patternType: "solid", fgColor: {rgb: "EA9999"}}}},
      {'value': claimData?.mech_claims || 0, style: {fill: {patternType: "solid", fgColor: {rgb: "EA9999"}}}},
    ]);

    const multiDataSet = [
      {
        columns: [
            {title: t('Song Title'), width: {wpx: 200}, style: {fill: {patternType: "solid", fgColor: {rgb: "EA9999"}}}},
            {title: t('Artist'), width: {wpx: 120}, style: {fill: {patternType: "solid", fgColor: {rgb: "EA9999"}}}},
            {title: t('Platform'), width: {wpx: 120}, style: {fill: {patternType: "solid", fgColor: {rgb: "EA9999"}}}},
            {title: t('Asset ID'), width: {wpx: 170}, style: {fill: {patternType: "solid", fgColor: {rgb: "EA9999"}}}},
            {title: t('ISRCs Linked'), width: {wpx: 120}, style: {fill: {patternType: "solid", fgColor: {rgb: "EA9999"}}}},
        ],
        data: data
      }
    ];

    return multiDataSet;
  };

  const topAssetsData = () => {
    const sortedData = topAssets?.sort((a,b) => b.estimated_revenue - a.estimated_revenue);
    const data = [];

    sortedData?.map((rowData, idx) => {
      data.push([
        {'value': rowData.asset_title || ''},
        {'value': rowData.asset_id || ''},
        {'value': rowData.estimated_revenue ? '$' + (parseFloat(rowData.estimated_revenue).toFixed(2)).toString() : ''},
        {'value': rowData.views || ''},
      ])
    });

    const multiDataSet = [
      {
        columns: [
            {title: t('Asset'), width: {wpx: 200}, style: {fill: {patternType: "solid", fgColor: {rgb: "EA9999"}}}},
            {title: t('Asset ID'), width: {wpx: 120}, style: {fill: {patternType: "solid", fgColor: {rgb: "EA9999"}}}},
            {title: t('Total Estimated Earnings'), width: {wpx: 170}, style: {fill: {patternType: "solid", fgColor: {rgb: "EA9999"}}}},
            {title: t('Views'), width: {wpx: 120}, style: {fill: {patternType: "solid", fgColor: {rgb: "EA9999"}}}},
        ],
        data: data
      }
    ];

    return multiDataSet;
  };

  const topVideosData = () => {
    const sortedData = topVideos?.sort((a,b) => b.estimated_revenue - a.estimated_revenue);
    const data = [];

    sortedData?.map((rowData, idx) => {
      data.push([
        {'value': rowData.video_title || ''},
        {'value': rowData.video_id || ''},
        {'value': rowData.estimated_revenue ? '$' + (parseFloat(rowData.estimated_revenue).toFixed(2)).toString() : ''},
        {'value': rowData.views || ''},
      ])
    });

    const multiDataSet = [
      {
        columns: [
            {title: t('Video Title'), width: {wpx: 200}, style: {fill: {patternType: "solid", fgColor: {rgb: "EA9999"}}}},
            {title: t('Video ID'), width: {wpx: 120}, style: {fill: {patternType: "solid", fgColor: {rgb: "EA9999"}}}},
            {title: t('Total Estimated Earnings'), width: {wpx: 170}, style: {fill: {patternType: "solid", fgColor: {rgb: "EA9999"}}}},
            {title: t('Views'), width: {wpx: 120}, style: {fill: {patternType: "solid", fgColor: {rgb: "EA9999"}}}},
        ],
        data: data
      }
    ];

    return multiDataSet;
  };

  const ExportFile = () => {
    return (<>
      {isMech ? <ExcelFile
          filename={name + ' - YouTube Monthly Report - ' + startMonthName + ' ' + startYear}
          element={<DownloadButton />}
        >
          <ExcelSheet dataSet={getPerformanceData()} name="Performance Summary" />
          <ExcelSheet dataSet={ytManualClaimsData()} name="YouTube Manual Claims" />
          <ExcelSheet dataSet={mechManualClaimsData()} name="Mechanicals Manual Claims" />
          <ExcelSheet dataSet={topAssetsData()} name="Top 100 Earning Assets" />
          <ExcelSheet dataSet={topVideosData()} name="Top 100 Earning Videos" />
        </ExcelFile>
        : <ExcelFile
          filename={name + ' - YouTube Monthly Report - ' + startMonthName + ' ' + startYear}
          element={<DownloadButton />}
        >
          <ExcelSheet dataSet={getPerformanceData()} name="Performance Summary" />
          <ExcelSheet dataSet={ytManualClaimsData()} name="YouTube Manual Claims" />
          <ExcelSheet dataSet={topAssetsData()} name="Top 100 Earning Assets" />
          <ExcelSheet dataSet={topVideosData()} name="Top 100 Earning Videos" />
        </ExcelFile>
      }
    </>)
  };

  return (<>
    {downloadStatus === 'success' ?
      <ExportFile />
      : <>
        <button
          type="button"
          className="block inline-flex items-center gap-x-1.5 rounded-md bg-gray-200 px-3 py-2 text-sm font-semibold text-gray-400 shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
        >
          <ArrowDownOnSquareIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
          Download Report
        </button>
      </>
    }
  </>)
}

export default DownloadExport;
