import React, { useEffect, useState} from 'react';
import Spinner from 'components/Icons/Spinner';

import { classNames } from 'components/utils';
import { getMonthName } from 'utils';
import { getMonthlyClaims } from 'api/expdata';
import { useTranslation } from 'react-i18next';

const MechManualClaims = ({ startMonth, startYear, endMonth, endYear, client }) => {
  const { t } = useTranslation();
  const monthName = getMonthName(startMonth);

  const header = [
    {'key': 'song_title', 'label': t('Song Title')},
    {'key': 'artist', 'label': t('Artist')},
    {'key': 'platform', 'label': t('Platform')},
    {'key': 'asset_id', 'label': t('Asset ID')},
    {'key': 'videos_claimed', 'label': t('ISRCs Linked')},
  ]

  const [loading, setLoading] = useState(true)
  const [claimData, setClaimData] = useState()
  const [totalClaims, setTotalClaims] = useState(0)

  useEffect(() => {
    if (client?.name) {
      setLoading(true);
      getMonthlyClaims(startMonth, startYear, null, null, client?.name).then(res => {
        setClaimData(res.data?.mech_results)
        setTotalClaims(res.data?.mech_claims)
        setLoading(false);
      }).catch(err => {
        console.log(err);
        setLoading(false);
      });
    }
  }, [startMonth, startYear, client]);

  return (<>
    <div className="mb-6">
      <h1 className="text-3xl font-bold tracking-tight text-blue-gray-900">{t('Mechanicals Manual Claims')}</h1>
      <p className="mt-1 max-w-3xl text-lg text-gray-500">{t(monthName)} {startYear}</p>
    </div>
    <div className="overflow-hidden bg-white shadow sm:rounded-lg mb-6">
      <div className="border-t border-gray-200">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
            <tr>
              {header.map((k, idx) => {
                return (
                  <th key={k.key} scope="col"
                    className={
                      classNames(
                        idx === 0 ? 'pl-4 sm:pl-6' : '',
                        "py-3.5 px-3 text-left text-sm font-semibold text-gray-900"
                      )
                    }
                  >
                    {k.label}
                  </th>
                )
              })}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {loading && (<tr>
              <td className="bg-white py-3 px-3" colSpan={4}>
                <div className="flex items-center justify-center">
                  <Spinner />&nbsp;Loading...
                </div>
              </td>
            </tr>)}
            {!loading && claimData && (<>
                {claimData.map((rowData, idx) => (
                  <tr key={'rowData__' + idx}>
                    {header.map((k, kIdx) => {
                      return (
                        <td key={'claim_' + idx + '_' + kIdx}
                          className={
                            classNames(
                              k.key === 'song_title' ? 'font-medium pl-4 sm:pl-6' : '',
                              "px-3 py-4 text-sm text-gray-500"
                            )
                          }
                        >
                          {k.key === 'song_title' && rowData.song_title}
                          {k.key === 'artist' && rowData.artist}
                          {k.key === 'platform' && rowData.platform}
                          {k.key === 'asset_id' && rowData.asset_id}
                          {k.key === 'videos_claimed' && rowData.videos_claimed}
                        </td>
                      )
                    })}
                  </tr>
                ))}
              </>)
            }
            <tr key='totalsRow'>
              <td key='totalClaims' className='font-medium pl-4 sm:pl-6 whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                Total
              </td>
              <td />
              <td />
              <td />
              <td className='font-medium whitespace-nowrap px-3 py-4 text-sm text-gray-500 '>{totalClaims}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </>);
}

export default MechManualClaims;
